import React, { useState, useEffect } from 'react';
import './App.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCsVmJgaRdzjkfBTtmFvsPwGNrCANrFE2s",
  authDomain: "tudes-77918.firebaseapp.com",
  projectId: "tudes-77918",
  storageBucket: "tudes-77918.appspot.com",
  messagingSenderId: "784180568449",
  appId: "1:784180568449:web:6a2a5736699ab531514880",
  measurementId: "G-VPQT0GJDEL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Função de SEO para adicionar meta tags e outros
const addSEO = () => {
  document.title = "Negritudes - Promovendo a Diversidade e a Cultura Afro-Brasileira";

  // Meta Description
  const metaDescription = document.createElement('meta');
  metaDescription.name = "description";
  metaDescription.content = "Acesse Negritudes.com.br e explore a riqueza da cultura afro-brasileira. Você será redirecionado para página de conteúdo especial no Somos Negritudes da Globo.";
  document.head.appendChild(metaDescription);
  
  // Open Graph (para redes sociais)
  const ogTitle = document.createElement('meta');
  ogTitle.setAttribute('property', 'og:title');
  ogTitle.content = "Negritudes - Promovendo a Diversidade e a Cultura Afro-Brasileira";
  document.head.appendChild(ogTitle);

  const ogDescription = document.createElement('meta');
  ogDescription.setAttribute('property', 'og:description');
  ogDescription.content = "Acesse o conteúdo exclusivo da plataforma Negritudes e promova a diversidade. Você será redirecionado para nossa página especial em Somos Globo.";
  document.head.appendChild(ogDescription);

  const ogUrl = document.createElement('meta');
  ogUrl.setAttribute('property', 'og:url');
  ogUrl.content = "https://negritudes.com.br";
  document.head.appendChild(ogUrl);
  
  const ogImage = document.createElement('meta');
  ogImage.setAttribute('property', 'og:image');
  ogImage.content = "https://negritudes.com.br/path-to-image.jpg";  
  document.head.appendChild(ogImage);
  
  const ogType = document.createElement('meta');
  ogType.setAttribute('property', 'og:type');
  ogType.content = "website";
  document.head.appendChild(ogType);
  
  // Twitter Card (para compartilhamento no Twitter)
  const twitterCard = document.createElement('meta');
  twitterCard.name = "twitter:card";
  twitterCard.content = "summary_large_image";
  document.head.appendChild(twitterCard);

  const twitterTitle = document.createElement('meta');
  twitterTitle.name = "twitter:title";
  twitterTitle.content = "Negritudes - Promovendo a Diversidade e a Cultura Afro-Brasileira";
  document.head.appendChild(twitterTitle);

  const twitterDescription = document.createElement('meta');
  twitterDescription.name = "twitter:description";
  twitterDescription.content = "Explore a cultura afro-brasileira. Acesse Negritudes.com.br para mais informações.";
  document.head.appendChild(twitterDescription);

  const twitterImage = document.createElement('meta');
  twitterImage.name = "twitter:image";
  twitterImage.content = "https://negritudes.com.br/path-to-twitter-image.jpg"; 
  document.head.appendChild(twitterImage);
};

// Componente principal
const App = () => {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    // Adiciona as meta tags para SEO
    addSEO();
    
    // Simulando uma chamada para o Google Analytics
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: document.title,
      });
    }

    // Atualiza a contagem regressiva a cada segundo
    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          redirectToExternal();
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    // Atraso para redirecionamento, permitindo indexação
    const timer = setTimeout(() => {
      redirectToExternal();
    }, 5000); // 5 segundos de espera

    // Cleanup
    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);

  const redirectToExternal = () => {
    window.location.href = "https://somos.globo.com/negritudes/";
  };

  return (
    <div>
      <h1>Negritudes - Promovendo a Diversidade e a Cultura Afro-Brasileira</h1>
      <p>Você será redirecionado em <span className="countdown">{countdown}</span> segundos para o conteúdo exclusivo no portal Somos Globo.</p>
      <p>Se não for redirecionado automaticamente, <a href="https://somos.globo.com/negritudes/">clique aqui</a>.</p>
    </div>
  );
};

export default App;
